import { Options, Vue } from 'vue-class-component';
import { mapState, mapMutations } from 'vuex';
import { sprintf } from 'sprintf-js';
import { BigNumber } from 'bignumber.js';
import { AppUtil } from './app.util';
import { AppConfig } from './app.config';
import { AppStore } from './app.store';
import { getRouteFrom } from './router';

const errImage = require('./assets/images/lazy/error.png');

@Options({
  computed: {
    ...mapState(['selectKey', 'collapsed', 'currentUrl', 'isLogin', 'sess', 'lang', 'routers', 'showDebugTag', 'isMao']),
  },
  methods: {
    ...mapMutations(['setCurrentUrl', 'toggleDebugTag', 'changeCollapsed']),
  },
})
export class AppVue extends Vue {
  version = AppConfig.$projectVersionCode;
  isNav = true;
  $store: any;

  async setLang(key) {
    await AppConfig.setLang(key);
    location.reload();
  }

  json(v) {
    return JSON.stringify(v);
  }

  sprintf(...args) {
    const fargs = [];
    for (const item of args) {
      if (typeof item !== 'undefined') {
        fargs.push(item);
      }
    }
    if (fargs.length < 2) {
      return '-';
    }
    return sprintf(...fargs);
  }

  toFloat(v) {
    if (v instanceof Number) {
      return v;
    }
    const fv = parseFloat(v);
    return isNaN(fv) ? 0 : fv;
  }

  percent(v, onlyInt?) {
    const fv = parseFloat(v);
    if (onlyInt) {
      return isNaN(fv) ? 0 : Math.round(fv * 100.0);
    }
    return isNaN(fv) ? 0 : Math.round(fv * 1000.0) / 10.0;
  }

  num(v) {
    // tslint:disable-next-line: radix
    return parseFloat((parseInt((parseFloat(v) * 100).toString()) / 100).toString());
  }

  // yyyy-MM-dd hh:mm:ss
  date(v, fmt) {
    return AppUtil.formatDate(AppUtil.newDate(v), fmt);
  }

  xstr(v, left, right) {
    return AppUtil.xstrMid(v, left, right);
  }

  isWeiXin() {
    const chk = new RegExp('MicroMessenger', 'i');
    const ua = navigator.userAgent.toLowerCase();
    return chk.test(ua);
  }

  isNotWeiXin() {
    return !this.isWeiXin();
  }

  copy(obj) {
    if (!obj) {
      return obj;
    }
    if (typeof obj !== 'object') {
      return obj;
    }
    return JSON.parse(JSON.stringify(obj));
  }

  int(v) {
    if (!v) {
      return 0;
    }
    const r = parseInt(v, 10);
    return isNaN(r) ? 0 : r;
  }

  float(v) {
    if (!v) {
      return 0.0;
    }
    const r = parseFloat(v);
    return isNaN(r) ? 0 : r;
  }

  ntf(v) {
    return v.replace(/[\n]+/g, '\\n');
  }

  toast(msg, tp?, duration?): Promise<any> {
    return AppUtil.toast(msg, tp, duration);
  }

  alert(msg, tp?): Promise<any> {
    return AppUtil.alert(msg, tp);
  }

  showNeedLogin() {
    this.alert('Login timeout');
    this.hrefTo('/public/login');
  }

  storeState(): any {
    return (this as any).$store.state;
  }

  async hrefTo(path, checkLogin?, params?: any) {
    if (checkLogin && !this.storeState().isLogin) {
      this.showNeedLogin();
      return;
    }

    // 页面回到顶部
    window.scrollTo(0, 0);
    this.$router.push({ path, query: params });
  }
  async hrefReplace(path, checkLogin?, params?: any) {
    if (checkLogin && !this.storeState().isLogin) {
      this.showNeedLogin();
      return;
    }

    // 页面回到顶部
    window.scrollTo(0, 0);
    this.$router.push({ path, query: params });
  }
  hrefBack() {
    this.$router.go(-1);
  }

  hrefBlank(url) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
  }

  error(data) {
    if (!data.code) {
      return this.toast(data, 'error', 4000);
    }
    return this.toast(data.msg, 'error', 4000);
  }

  // 高亮选中行
  // @mult: 多行: true, 单行: false
  toggleTr($e, mult = true) {
    if (!$e || !$e.target) {
      return;
    }

    let e = $e.target as HTMLElement;
    if (e.tagName === 'INPUT' || e.tagName === 'BUTTON' || e.tagName === 'A' || e.classList.contains('--out-tr-selected')) {
      return;
    }

    let n = 0;
    while (e && e.tagName !== 'TR' && e.tagName !== 'BODY' && n < 10) {
      e = e.parentElement;
      if (e.tagName === 'INPUT' || e.tagName === 'BUTTON' || e.tagName === 'A' || e.classList.contains('--out-tr-selected')) {
        return;
      }
      n++;
    }

    if (!e || e.tagName !== 'TR') {
      return;
    }

    if (e.classList.contains('tr-selected')) {
      e.classList.remove('tr-selected');
      this.setChildrenBackgroundColor(e, 'transparent');
    } else {
      if (!mult) {
        const nodes = document.getElementsByClassName('tr-selected');
        for (let i = 0; i < nodes.length; i++) {
          const c = nodes.item(i) as HTMLElement;
          c.classList.remove('tr-selected');
          this.setChildrenBackgroundColor(c, 'transparent');
        }
      }
      e.classList.add('tr-selected');
      this.setChildrenBackgroundColor(e, AppUtil.randLightColor(180));
    }
  }

  setChildrenBackgroundColor(e: HTMLElement, backgroundColor: string) {
    for (let j = 0; j < e.children.length; j++) {
      const ci = e.children.item(j) as HTMLElement;
      ci.style.backgroundColor = backgroundColor;
    }
  }
  rondomPass(number) {
    const arr = [];
    const arr1 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    for (let i = 0; i < number; i++) {
      const n = Math.floor(Math.random() * 10);
      arr[i] = arr1[n];
      //document.write(arr1[n]);
    }

    const val = arr.join('');
    return new Date().getTime().toString() + val + AppStore.state.sess.id;
  }

  vsrc(v) {
    if (v) {
      return '' + v;
    }
    return errImage;
  }

  strDateTime(str) {
    const r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
    if (r === null) {
      this.toast('输入的格式不正确; yyyy-mm-dd');
      return false;
    }
    return true;
    // var d = new Date(r[1], r[3] - 1, r[4]);
    // return d.getFullYear() == r[1] && d.getMonth() + 1 == r[3] && d.getDate() == r[4];
  }

  mao(v: string) {
    return AppUtil.mao(v, (this as any).isMao);
  }

  file(path: any) {
    if (!path) {
      return '';
    }
    if (typeof path === 'object') {
      return path;
    }
    if (('' + path).indexOf('/') > -1) {
      return path;
    }
    return AppConfig.getFileBaseUrl(path);
  }

  resetData(form, formBase) {
    if (form === null) {
      return this.copy(formBase);
    }

    for (const key of Object.keys(formBase)) {
      if (formBase[key] instanceof Object) {
        form[key] = this.copy(formBase[key]);
      } else {
        form[key] = formBase[key];
      }
    }
    return form;
  }

  regularReplace(form, name: string, pertten, replace) {
    const keys = Object.keys(form);
    if (!form || !name || !pertten || !keys.includes(name)) {
      return;
    }
    form[name] = form[name].replace(pertten, replace);
  }

  checkPhone(value) {
    let regPone = null;
    const mobile = /^(1[3456789]\d{9})$/; //手机号
    const tel = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/; // 座机
    if (value !== null && value.charAt(0) === '0') {
      // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
      regPone = tel;
    } else if (value !== null && value.charAt(0) !== '0') {
      regPone = mobile;
    }
    if (regPone === null) {
      return false;
    } else if (!regPone.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  regularReplaceOther(form, name: string, fn) {
    const keys = Object.keys(form);
    if (!form || !name || !fn || !keys.includes(name)) {
      return;
    }
    form[name] = fn(form[name]);
  }
  // abs: 绝对正
  // fixed: 精度保留
  formatFloatInput(v: string, abs = false, fixed = 2) {
    if (abs) {
      v = v.replace(/[^.0-9]/gi, '');
    } else {
      v = v.replace(/[^\-.0-9]/gi, '');
      v = (v && v[0] === '-' ? '-' : '') + v.replace(/-+/gi, '');
    }

    v = v.replace(/(\.[^.]*).*$/gi, '$1'); // 去除多余.
    if (v && v[0] === '.') {
      v = '0' + v;
    } else if (v.length > 1 && v[0] === '-' && v[1] === '.') {
      v = '-0' + v;
    }
    const regx = new RegExp('^([^.]+)(\\.\\d{' + fixed + '}).*$', 'gi');
    // console.log(regx);
    return v.replace(regx, '$1$2');
  }

  strParams(params) {
    if (params === null) {
      return null;
    }
    for (const k of Object.keys(params)) {
      params[k] = '' + params[k];
    }
    return params;
  }

  sel(...args) {
    if (!this.$route) {
      return false;
    }

    let routePath = this.$route.fullPath;
    if (routePath.includes('/platform/detail')) {
      const preRoute = getRouteFrom();
      if (preRoute && preRoute.fullPath) {
        routePath = preRoute.fullPath;
      }
    }

    for (const v of args) {
      if (routePath.includes(v)) {
        return true;
      }
    }
    return false;
  }


  menu() {
    this.isNav = true;
  }
  close() {
    this.isNav = false;
  }
}
