import { Options } from 'vue-class-component';
import { AppUtil } from '../../../app.util';
import { AppVue } from '../../../app.vue';

@Options({
  style: require('./test.scss'),
  template: AppUtil.joinTemplate(
    require('./test.html'), //
    [
      require('./modals/hello1/hello1.html'), //
      require('./modals/hello2/hello2.html'), //
    ],
  ),
})
export default class PageTest extends AppVue {
  onClick() {}
}
