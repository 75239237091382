import { createStore } from 'vuex';
import { AppConfig } from './app.config';

export const AppStore = createStore({
  state: {
    // 选中的菜单
    selectKey: '',

    // 左侧栏展开与否
    collapsed: true,

    // route change
    currentUrl: { path: '', params: null },

    // login info
    isLogin: false,
    sess: null,

    // language
    lang: 'en',

    routers: [],

    // 显示调试标签
    showDebugTag: false,
    isMao: false, // 使用锚标记模式
  },
  mutations: {
    toggleCollapsed(state) {
      state.collapsed = !state.collapsed;
    },

    async setSession(state, data) {
      state.sess = data.sess;
      state.isLogin = data.isLogin;
    },

    setCurrentUrl(state, data) {
      state.currentUrl = data;
    },

    setLang(state, data) {
      state.lang = data;
    },

    setRouters(state, data) {
      let have = false;
      const key = data.href;
      const routers = [];
      for (const item of state.routers) {
        item.selected = false;
        if (item.key === key) {
          item.selected = true;
          have = true;
        }
        routers.push(item);
      }
      if (!have) {
        routers.push({
          key: key,
          selected: true,
          router: data,
        });
      }

      state.routers = routers;
    },

    setDeleteRoutersByKey(state, data) {
      const routers = [];
      for (const item of state.routers) {
        if (item.key !== data.key) {
          routers.push(item);
        }
      }

      state.routers = routers;
    },
    setDeleteOtherRoutersByKey(state, data) {
      const filters = data.filters ? data.filters : [];
      const routers = [];
      for (const item of state.routers) {
        let have = false;
        for (const f of filters) {
          if (item.router.fullPath.includes(f)) {
            have = true;
            break;
          }
        }
        if (have) {
          if (item.key === data.key) {
            routers.push(item);
          }
        } else {
          routers.push(item);
        }
      }
      state.routers = routers;
    },
    setClearRouters(state, data) {
      const filters = data.filters ? data.filters : [];
      const routers = [];
      for (const item of state.routers) {
        for (const f of filters) {
          if (item.router.fullPath.includes(f)) {
            if (item.selected) {
              routers.push(item);
              break;
            }
          }
        }
      }

      state.routers = routers;
    },
    setDebugTag(state, data) {
      state.showDebugTag = !!data;
    },
    toggleDebugTag(state) {
      state.showDebugTag = !state.showDebugTag;
    },
    setIsMao(state, data) {
      state.isMao = data;
    },
  },
});
