import { Options } from 'vue-class-component';
import { AppVue } from '../../../app.vue';
import Footer from '../../../components/footer/footer';
import './about.scss';

@Options({
  template: require('./about.html'),
  components: {
    'md-footer': Footer,
  },
  props: {},
})
export class PageAbout extends AppVue {
  async created() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 414) {
      this.isNav = false;
    } else {
      this.isNav = true;
    }
  }

  mounted() {

  }

}
