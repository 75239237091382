import { Options } from 'vue-class-component';
import { AppVue } from '../../../app.vue';
import { AppConfig } from '../../../app.config';
import { AppUtil, Passwordstrength } from '../../../app.util';

@Options({
  style: require('./example.scss'),
  template: require('./example.html'),
  components: {},
})
export default class PageExample extends AppVue {
  form = {
    password: '',
    content1: '',
    content2: '',
  };
  passwordCheck: Passwordstrength = null;
  passLevel = 0;

  counter = 0;

  created() {
    AppConfig.addOnLeavePage(this);

    this.counter = AppConfig.getMemState(this, 'this.counter', this.counter);
  }

  viewDidLeave() {
    AppConfig.setMemState(this, 'this.counter', this.counter);
    return true;
  }

  mounted() {}

  onMenuClick(data, e) {
    console.log(this.json(data));
    if (!data) {
      return;
    }

    // isLink:<a></a>不做处理, path为空不做处理
    if (data.isLink || !data.path) {
      return;
    }

    this.hrefTo(data.path);
  }

  onCount() {
    this.counter++;
  }

  onPasswordInput() {
    this.passwordCheck = AppUtil.parsePasswordstrength(this.form.password);
    this.passLevel = AppUtil.checkasswordstrength(this.form.password);
  }

  onKeyup1(form, name: string, fn) {
    const keys = Object.keys(form);
    if (!form || !name || !fn || !keys.includes(name)) {
      return;
    }
    form[name] = fn(form[name]);
  }

  onKeyup2(form, name: string, pertten, replace) {
    const keys = Object.keys(form);
    if (!form || !name || !pertten || !keys.includes(name)) {
      return;
    }
    form[name] = form[name].replace(pertten, replace);
  }

  // abs: 绝对正
  // fixed: 精度保留
  formatFloatInput(v: string, abs = false, fixed = 2) {
    if (abs) {
      v = v.replace(/[^.0-9]/gi, '');
    } else {
      v = v.replace(/[^\-.0-9]/gi, '');
      v = (v && v[0] === '-' ? '-' : '') + v.replace(/-+/gi, '');
    }

    v = v.replace(/(\.[^.]*).*$/gi, '$1'); // 去除多余.
    if (v && v[0] === '.') {
      v = '0' + v;
    } else if (v.length > 1 && v[0] === '-' && v[1] === '.') {
      v = '-0' + v;
    }
    const regx = new RegExp('^([^.]+)(\\.\\d{' + fixed + '}).*$', 'gi');
    // console.log(regx);
    return v.replace(regx, '$1$2');
  }
}
