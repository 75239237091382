import { Options } from 'vue-class-component';
import { AppVue } from '../../../app.vue';

@Options({
  style: require('./icons.scss'),
  template: require('./icons.html'),
})
export default class PageIconsDark extends AppVue {
  onClick() {}
}
