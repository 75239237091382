import { Options } from 'vue-class-component';
import { AppVue } from '../../../app.vue';
import { AppNet } from '../../../app.net';
import Footer from '../../../components/footer/footer';
import './home.scss';
import { AppConfig } from '../../../app.config';
import { AppUtil } from '../../../app.util';

@Options({
  template: require('./home.html'),
  components: {
    'md-footer': Footer,
  },
  props: {},
})
export class PageHome extends AppVue {

  async created() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 414) {
      this.isNav = false;
    } else {
      this.isNav = true;
    }
  }

  mounted() {
 
  }
}
