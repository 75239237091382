import { Options, Vue } from 'vue-class-component';

import { AppVue } from '../../app.vue';

@Options({
    style: require('./footer.scss'),
    template: require('./footer.html'),
})


export default class Footer extends AppVue {
    created() {
    }

}
