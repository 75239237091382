import { Options } from 'vue-class-component';
import { createApp } from 'vue';
import { AppConfig } from './app.config';
import router from './router';
import lazyPlugin from 'vue3-lazy';
import './main.scss';
// Import Bootstrap's bundle (all of Bootstrap's JS + Popper.js dependency)
import Bootstrap from '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import { AppStore } from './app.store';

// Import Bootstrap Switch (light-switch-bootstrap)
import '../node_modules/light-switch-bootstrap/switch.js';

// 右键菜单
import contextmenu from 'vue3-contextmenu';
import 'vue3-contextmenu/dist/vue3-contextmenu.css';
import { AppVue } from './app.vue';





@Options({
  template: require('./main.html'),
})
export class App extends AppVue { }

// 同步启动
(async () => {
  (window as any).Bootstrap = Bootstrap;

  await AppConfig.init();

  createApp(App)
    .use(AppStore)
    .use(router)
    .use(contextmenu)
    .use(lazyPlugin, {
      loading: require('./assets/images/lazy/loading.gif'), // 图片加载时默认图片
      error: require('./assets/images/lazy/error.png'), // 图片加载失败时默认图片
    })
   
    .mount('#app');


})();
