import { Options } from 'vue-class-component';
import { AppConfig } from '../../app.config';
import { AppVue } from '../../app.vue';

@Options({
  style: require('./bootstrap.scss'),
  template: require('./bootstrap.html'),
})
export default class PageBootstrap extends AppVue {
  created() {
    AppConfig.addOnLeavePage(this);
  }

  viewDidLeave() {
    return true;
  }

  mounted() {}
}
