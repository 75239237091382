import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageXError404 from '../pages/public/x-error-404/x-error-404';
import { AppStore } from '../app.store';
import { AppConfig } from '../app.config';
import PageExample from '../pages/public/example/example';
import PageTest from '../pages/public/test/test';
import PageBootstrap from '../pages/bootstrap/bootstrap';
import PageIconsDark from '../pages/public/icons-dark/icons';
import PageIconsLight from '../pages/public/icons-light/icons';
import { PageHome } from '../pages/app/home/home';
import { PageAbout } from '@/pages/app/about/about';
import { PageJoining } from '@/pages/app/joining/joining';
import { PageProduct } from '@/pages/app/product/product';
import { PageSupport } from '@/pages/app/support/support';

const pageBaseName = ' - 秘云';

const routes: Array<RouteRecordRaw> = [
  { path: '', redirect: '/public/home' },

  /** 公共页面 **/
  { path: '/bootstrap', meta: { title: 'Bootstrap' + pageBaseName }, component: PageBootstrap },
  { path: '/public/example', meta: { title: 'Model example' + pageBaseName }, component: PageExample },
  { path: '/public/test', meta: { title: 'Test' + pageBaseName }, component: PageTest },
  { path: '/public/icons-dark', meta: { title: 'Dark icons' + pageBaseName }, component: PageIconsDark },
  { path: '/public/icons-light', meta: { title: 'Light icons' + pageBaseName }, component: PageIconsLight },
  { path: '/public/home', meta: { title: 'Home' + pageBaseName }, component: PageHome },
  { path: '/public/about', meta: { title: '关于我们' + pageBaseName }, component: PageAbout },
  { path: '/public/join', meta: { title: '城市加盟' + pageBaseName }, component: PageJoining },
  { path: '/public/product', meta: { title: '产品' + pageBaseName }, component: PageProduct },
  { path: '/public/support', meta: { title: '支持和服务' + pageBaseName }, component: PageSupport },

  /** PLATFORM 页面 **/


  /* 1.所有页面name值不能重复, 否则将匹配到错误页面 */
  // --------------------------------
  /** 必须放最后 **/
  /** 公共页面   **/
  { path: '/:pathMatch(.*)*', meta: { title: 'not-found' }, component: PageXError404 },
];

// 修正vue路由bug, 同name路由无法访问
const router = createRouter({
  history: AppConfig.$useMao ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to: any) => {
  // 调用页面离开事件
  const yes = await AppConfig.clearOnLeavePage();
  if (!yes) {
    return false;
  }

  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  AppStore.commit('setRouters', { ...to });
  return true;
});

let routeFromRote = null;
export const getRouteFrom = () => routeFromRote;

router.beforeEach(async (to, from, next) => {
  // ...
  routeFromRote = from;

  // console.log('router.beforeEach ====> ', to, from, next);

  next();
});

export default router;
