// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../assets/images/home_logo.png");
var ___HTML_LOADER_IMPORT_1___ = require("../../assets/images/icon/dd.png");
var ___HTML_LOADER_IMPORT_2___ = require("../../assets/images/icon/ph.png");
var ___HTML_LOADER_IMPORT_3___ = require("../../assets/images/icon/em.png");
var ___HTML_LOADER_IMPORT_4___ = require("../../assets/images/icon/addr.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<div class=\"component--footer\" id=\"foot\"> <div class=\"wrap flex\"> <div class=\"logo flex-1\"> <div> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\"> </div> </div> <div class=\"flex-1\"> <div class=\"\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"\"> 联系钉钉</div> <div class=\"\">13585868407</div> </div> <div class=\"flex-1\"> <div class=\"\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"\"> 手机电话</div> <div class=\"\"><a href=\"tel:13585868407\">13585868407</a></div> </div> <div class=\"flex-1\"> <div class=\"\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"\"> 联系邮箱</div> <div class=\"\"><a href=\"mailto:miyuncloud@aliyun.com\">miyuncloud@aliyun.com</a></div> </div> <div class=\"flex-2\"> <div class=\"\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"\"> 联系地址</div> <div class=\"\">上海市诸光路1588弄虹桥世界中心L1-B 506</div> </div> </div> <div class=\"pc\"> © 2022 秘云信息科技（上海）有限公司 版权所有 不得转载【沪ICP备11005544号-28】 </div> </div>";
// Exports
module.exports = code;